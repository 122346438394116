import { Container as RBContainer } from 'react-bootstrap';
import styled from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';

export const Container = styled(RBContainer)`
    padding: 18px 0;
    ${breakpointUp.lg`
        width: 1192px;
    `}
`;

export const StyledFooter = styled.footer`
    padding-bottom: 10px;

    .sl-footer-image-container {
        display: block;
    }

    .sl-golden-thread-image {
        height: auto;
        width: 100%;

        ${breakpointUp.lg`
            width: 100%;
            height: auto;
        `}
    }

    .flex-container {
        ${breakpointUp.lg`
            display: flex;
            flex-wrap: wrap;
        `}
    }

    .main-footer-logo-container {
        width: 30%;

        img {
            height: auto;
            width: 200px;
            margin-bottom: 10px;

            ${breakpointUp.lg`
                margin: 0 16px 16px;
            `}
        }
    }

    .main-footer-icon-content {
        max-width: 293px;

        ${breakpointUp.lg`
            margin-left: 16px;
        `}
    }

    ul {
        list-style: none;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    strong {
        color: ${({ theme }) => theme.colors.grey};
    }

    .main-footer-policy-strip {
        border-top: 3px solid ${({ theme }) => theme.colors.borderGrey};
        padding-bottom: 24px;
        margin-top: 24px;

        ${breakpointUp.lg`
           display: flex;
           justify-content: space-between;
           align-items: flex-end;
        `}
    }

    .main-footer-provider-copyright {
        display: none;
        ${breakpointUp.lg`
            display: block;
        `}
        p {
            color: ${({ theme }: any) => theme.colors.blue};
            margin-left: 20px;
        }
    }

    .bg-paleGrey {
        background-color: ${({ theme }) => theme.colors.greyLight};
        padding: 0 0 5px;
    }

    .main-footer-provider-container {
        padding-top: 10px;

        ${breakpointUp.lg`
          display: flex;
          justify-content: space-between;
        `}
    }

    .main-footer-provider-accordion {
        ${breakpointUp.lg`
            width: 70%;
        `}
        img {
            margin-right: 10px;
            height: auto;
        }

        span {
            color: ${({ theme }) => theme.colors.blue};
        }
    }

    .accordion-toggle {
        background-color: transparent;
    }
    .accordion-panel-box {
        border-left: 1px solid #fb0;
        margin-left: 8px;
        padding-left: 16px;
    }
    .main-footer-policy-strip .policy-strip-list {
        display: block;
        padding: 16px 0;

        ${breakpointUp.lg`
            padding: 16px;
        `}

        li {
            display: inline-block;
            padding-right: 12px;

            ${breakpointUp.lg`
                padding-right: 32px;
            `}
        }
        & li:not(:last-child) {
            padding-bottom: 16px;
        }
    }

    .hide {
        display: none;
    }

    .show {
        display: block;
        padding-top: 8px;
    }

    .main-footer-mobile {
        color: ${({ theme }: any) => theme.colors.blue};
        margin-top: 10px;
        ${breakpointUp.lg`
                display: none;
        `}
    }
`;
