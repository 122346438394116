import SLLogo from '../../assets/img/sl-logo.svg';
import Goldenthread from '../../assets/img/golden-thread.png';
import IconDownArrow from '../../assets/img/icon-downarrow.svg';
import { StyledFooter } from './styles';
import { H5, P } from 'components/ui/Typography';
import dayjs from 'dayjs';

export default function Footer() {
    const currentYear = dayjs().year();

    return (
        <StyledFooter>
            <div className="sl-footer-image-container">
                <img src={Goldenthread} className="sl-golden-thread-image" alt="alt text" width="252" height="280" />
            </div>

            <div className="container footer-container-cls">
                <div className="flex-container">
                    <div className="main-footer-logo-container">
                        <a href="/">
                            <img
                                src={SLLogo}
                                alt="Standard Life | Making Good Choices With Your Life Savings"
                                width="260"
                                height="42"
                            />
                        </a>

                        <div className="main-footer-icon-content">
                            <P>
                                <strong>Part of Phoenix Group</strong>
                            </P>
                        </div>
                    </div>

                    <div className="main-footer-section">
                        <H5>Help and Support</H5>
                        <ul className="main-footer-section-items">
                            <li>
                                <a href="https://www.standardlife.ie/contact-and-help/contact-us" id="Be Scam Aware">
                                    Contact us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="main-footer-policy-strip footer-cls">
                    <ul className="policy-strip-list">
                        <li>
                            <a href="https://www.standardlife.ie/contact-and-help/accessibility" id="Accessibility">
                                {' '}
                                Accessibility
                            </a>
                        </li>
                        <li>
                            <a href="https://www.standardlife.ie/legal-information" id=" Legal information">
                                {' '}
                                Legal information
                            </a>
                        </li>
                        <li>
                            <a href="https://www.standardlife.ie/privacy" id="Privacy Policy">
                                {' '}
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href="https://www.standardlife.ie/cookie-policy" id="Cookie Policy">
                                {' '}
                                Cookie Policy
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="bg-paleGrey">
                <div className="container main-footer-provider-container">
                    <div className="main-footer-provider-accordion">
                        <section className="">
                            <button className="accordion-toggle">
                                <span>
                                    <img src={IconDownArrow} alt="down-arrow" />
                                    Brand website and provider details
                                </span>
                            </button>

                            <div className="panel show">
                                <div className="accordion-panel-box">
                                    <P>
                                        This site features Irish products provided for residents of the Republic of
                                        Ireland by Standard Life International dac.
                                    </P>

                                    <P>
                                        Standard Life International dac is regulated by the Central Bank of Ireland.
                                        Standard Life International dac is a designated activity company limited by
                                        shares and registered in Dublin, Ireland (408507) at 90 St Stephen's Green,
                                        Dublin D02 F653.
                                    </P>

                                    <P>
                                        &copy; {currentYear} Phoenix Group Management Services Limited. All rights
                                        reserved.
                                    </P>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="main-footer-provider-copyright">
                        <P>&copy; {currentYear} Phoenix Group Management Services Limited. All rights reserved.</P>
                    </div>
                </div>
            </div>
            <div className="container main-footer-mobile">
                <P>&copy; {currentYear} Phoenix Group Management Services Limited. All rights reserved.</P>
            </div>
        </StyledFooter>
    );
}
